import { IntercomProvider } from 'react-use-intercom';
import type { ReactNode } from 'react';
import config from '@/config';
import { isProduction } from '@/src/shared/utils/isProduction';
import { IntercomLoader } from './IntercomLoader';
import { useRouter } from 'next/router';
import { DASHBOARD_PREFIX } from '@/core/routes';
import { useSelector } from 'react-redux';
import { selectPopups } from '@/redux/popup-manager/selectors';
import { useMemo } from 'react';
import { isEmptyArray } from '@/src/shared/utils/array';
import { INTERCOM_CONFIGS } from '@/src/core/constants/intercomConfigs';
import { useIsMobile } from '@/src/shared/hooks/utils/useIsMobile';

const INTERCOM_APP_ID = config?.intercom?.IntercomAppID;

interface Props {
  children?: ReactNode;
}

export default function IntercomChatBot({ children }: Props) {
  const router = useRouter();
  const popups = useSelector(selectPopups);
  const isMobile = useIsMobile();

  const isDashboard = useMemo(() => {
    return router.asPath.includes(DASHBOARD_PREFIX);
  }, [router.asPath]);

  const isAnyPopupOpen = useMemo(() => {
    return !isEmptyArray(popups);
  }, [popups]);

  if (!isProduction()) return null;
  return (
    <IntercomProvider
      appId={INTERCOM_APP_ID}
      initializeDelay={
        isMobile
          ? INTERCOM_CONFIGS.initializeDelayMobile
          : INTERCOM_CONFIGS.initializeDelay
      }
    >
      <IntercomLoader />
      {isDashboard ? (
        <style jsx global>{`
          .intercom-lightweight-app-launcher,
          .intercom-app :nth-child(2) {
            @media (max-width: 768px) {
              bottom: 64px !important;
              right: 4px !important;
            }
          }
        `}</style>
      ) : (
        <style jsx global>{`
          .intercom-lightweight-app-launcher,
          .intercom-app :nth-child(2) {
            @media (max-width: 768px) {
              right: 4px !important;
            }
          }
        `}</style>
      )}
      {isAnyPopupOpen && (
        <style jsx global>{`
          .intercom-lightweight-app-launcher,
          .intercom-app :nth-child(2) {
            display: none !important;
            visibility: hidden !important;
          }
        `}</style>
      )}
      {children}
    </IntercomProvider>
  );
}
